//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VisibilitySensor from 'vue-visibility-sensor';
import countTo from 'vue-count-to';
import feather from 'feather-icons';
export default {
  components: {
    countTo,
    VisibilitySensor,
  },
  data() {
    return {
      countUp: true,
      status: false,
      counterUpContent: [
        {
          icon: 'heart',
          endVal: 50000,
          desc: `counter.customers`,
        },
        {
          icon: 'clock',
          endVal: 25,
          desc: `counter.yearsOfOperation`,
        },
        {
          icon: 'check-circle',
          endVal: 5,
          desc: `counter.subCompanies`,
        },
      ],
    };
  },
  methods: {
    onChange(isVisible) {
      if (isVisible) {
        this.status = true;
      }
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
