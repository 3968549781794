//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HomeHeader from '@/modules/home/components/home-header.vue';
import Services from '@/modules/home/components/services.vue';
// import Owner from '@/modules/home/components/owner.vue';
import Counter from '@/modules/home/components/counter.vue';

// import Companies from '@/modules/home/components/companies.vue';
import i18n from '@/vueI18n';
import contactsContainer from './contacts-container.vue';

export default {
  components: {
    [HomeHeader.name]: HomeHeader,
    contactsContainer,
    Services,
    // Owner,
    Counter,
    // Companies,
  },
  data() {
    return {
      input: '',
    };
  },
  created() {
    this.fileName = this.$route.params.fileName;
  },
  computed: {
    compiledHtml: function () {
      return this.input;
    },
    language() {
      return i18n.locale;
    },
  },
  watch: {
    language() {
      document.title =
        this.i18n('app.title') +
        ' | ' +
        this.i18n('app.home');
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
