var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"contact"}},[_c('div',{staticClass:"contacts-container"},[_c('Contact')],1),_c('MapContainer',{attrs:{"lat":"31.26471061441738","lng":"29.998701774796327","marker":[
      {
        nameEn: 'Sama El Baraka Headquarters',
        nameAr: 'الفرع الرئيسي لسما البركة',
        lat: '31.2671402',
        lng: '29.9948399',
        url: 'https://www.google.com/maps/@31.2647106,29.9987018,15z',
      } ],"productPage":"false","divId":"mainBranch"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }