//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Icon } from '@iconify/vue2';
import i18n from '@/vueI18n';

export default {
  data() {
    return {
      data: [
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/logos%202%20-01.png?alt=media&token=e6c9d8b7-b6ea-4e8e-a6eb-12a0d217d0c5',
          title: {
            en: 'Sama El Baraka Import and Export',
            ar: 'سما البركة للإستيراد والتصدير',
          },
          desc: {
            en: 'As part of Al Faisal International Group, the company manages imports, exports, and plans future expansions.',
            ar: 'كجزء من مجموعة الفيصل الدولية، تدير الشركة عمليات الاستيراد والتصدير وتخطط للتوسع في المستقبل.',
          },
          id: 'sb',

          to: 'internal',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/homepage%2Fsama-el-baraka.webp?alt=media&token=3e6bb7c4-8653-4901-a584-406805518921',
          url: '/sama-el-baraka',
          title: {
            en: 'Sama El Baraka for Water Treatment',
            ar: 'سما البركة لمعالجة المياه',
          },
          desc: {
            en: 'Sama Al Baraka, part of Al Faisal Group, excels in manufacturing high-quality water purification systems in Egypt since 2006.',
            ar: 'سما البركة ، جزء من مجموعة الفيصل ، تتفوق في تصنيع أنظمة تنقية المياه عالية الجودة في مصر منذ عام 2006.',
          },
          id: 'samaElBaraka',
          to: 'internal',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/logos%203%20-01-01.png?alt=media&token=834aa3b4-5e63-4422-8514-932e912779df',
          title: {
            en: 'Lama Tours for Tourism & Travel',
            ar: 'لاما للسياحة والسفر',
          },
          desc: {
            en: 'Lama Tours is a renowned Egyptian company offering comprehensive travel services and packages.',
            ar: 'شركة لاما للجولات هي شركة مصرية مشهورة تقدم خدمات وباقات سفر شاملة.',
          },
          id: 'lama',

          to: 'internal',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/homepage%2FEsli%20Logo.webp?alt=media&token=15159da7-f111-4e8d-84cc-753494d00ebe',
          title: {
            en: 'Esli Egypt',
            ar: 'إسلي مصر',
          },
          desc: {
            en: 'Esli is a prominent water treatment company in Turkey, with a strong presence and growth in Egypt and Europe.',
            ar: 'إسلي هي شركة بارزة في مجال معالجة المياه في تركيا، مع وجود قوي ونمو في مصر وأوروبا.',
          },
          url: 'https://esliegypt.com/en/',
          id: 'esli',
          to: 'external',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/Asset%201%404x.png?alt=media&token=3f178558-6556-479c-b57d-af2f2f3d3a21',
          title: {
            en: 'RFTIC for Technology',
            ar: 'RFTIC للتكنولوجيا',
          },
          desc: {
            en: 'RFTIC, part of Al-Faisal Group, is a leading ISO-certified water treatment company in Egypt.',
            ar: 'RFTIC، جزء من مجموعة الفيصل، هي شركة رائدة في مجال معالجة المياه في مصر ومعتمدة بشهادة ISO.',
          },
          id: 'rftic',

          to: 'internal',
        },

        {
          src: 'https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/homepage%2FPuricom%20%20(1).webp?alt=media&token=89628b68-d6c0-464e-825c-8c3aee225add',
          url: 'https://www.puricomegypt.com/',
          title: {
            en: 'Puricom Egypt',
            ar: 'بوريكوم مصر',
          },
          desc: {
            en: 'Puricom is a global, people-oriented water purification company committed to promoting health and quality.',
            ar: 'تعتبر بوريكوم شركة عالمية مركزة على الناس في مجال تنقية المياه، ملتزمة بتعزيز الصحة والجودة.',
          },
          id: 'puricom',
          to: 'external',
        },
      ],
    };
  },
  components: {
    Icon,
  },
  computed: {
    isRTL() {
      return i18n.locale == 'ar';
    },
  },
};
