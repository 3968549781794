//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Contact from '@/modules/home/components/contact.vue';
import MapContainer from '@/shared/map-container/map-container.vue';
export default {
  components: {
    [MapContainer.name]: MapContainer,
    Contact,
  },
};
