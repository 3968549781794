//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '@/vueI18n';

export default {
  name: 'app-home-header',
  computed: {
    language() {
      return i18n.locale;
    },
  },
};
