//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate';
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import VuePhoneNumberInput from 'vue-phone-number-input';
import i18n from '@/vueI18n';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VuePhoneNumberInput,
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
        company: '',
        phone: null,
      },
      companiesEnglish: [
        'Sama El Baraka for Water Treatment & Environment Technology',
        'Sama El Baraka Import and Export',
        'Lama Tours for Tourism and Travel',
        'Rftic for Technology and Industrial',
        'Puricom Egypt',
        'Esli Egypt',
      ],
      companiesArabic: [
        'سما البركة لتكنولوجيا معالجة المياه و البيئة',
        'سما البركة لعموم الاستيراد والتصدير',
        'لما للسياحة والسفر',
        'مصنع رفتك للتكنولوجيا والصناعة',
        'بيوريكم إيجيبت',
        'إسلي إيجيبت',
      ],
      TRansObject: {
        countrySelectorLabel: 'Country Code',
        countrySelectorError: 'Choose a country',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'إختر بلد',
        phoneNumberLabel: 'رقم الهاتف',
        example: 'مثال :',
      },
    };
  },
  computed: {
    ...mapGetters({
      loading: 'home/loading',
    }),
    language() {
      return i18n.locale;
    },
    companies() {
      if (this.language == 'ar') {
        return this.companiesArabic;
      }
      return this.companiesEnglish;
    },
  },
  methods: {
    ...mapActions({
      doSubmitContactForm: 'home/doSubmitContactForm',
    }),
    validateForm() {
      if (
        this.formData.firstName == '' ||
        this.formData.lastName == '' ||
        this.formData.email == '' ||
        this.formData.phoneNumber == '' ||
        this.formData.message == '' ||
        this.formData.company == ''
      ) {
        return false;
      }
      return true;
    },
    onSubmit() {
      if (this.validateForm()) {
        this.doSubmitContactForm({
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          email: this.formData.email,
          phoneNumber: this.formData.phone.formattedNumber,
          message: this.formData.message,
          company: this.formData.company,
          language: this.language,
        });
        this.showNotif('success');
        this.formData.firstName = '';
        this.formData.lastName = '';
        this.formData.email = '';
        this.formData.phoneNumber = '';
        this.formData.message = '';
        this.formData.company = '';
      } else {
        this.showNotif('error');
      }
    },
    showNotif(value) {
      if (value == 'success')
        this.$notify({
          group: 'notification',
          title: this.i18n('contact.form.success'),
          text: this.i18n(
            'contact.form.createdSuccessfully',
          ),
          type: 'success',
          position: 'top right',
        });
      else {
        this.$notify({
          group: 'notification',
          title: this.i18n('contact.form.error'),
          text: this.i18n('contact.form.errorDescription'),
          type: 'error',
          position: 'top right',
        });
      }
    },
    getInput(payload) {
      this.formData.phone = payload;
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
